import { Address, toNano } from '@ton/core';
import WebApp from '@twa-dev/sdk';

export const cacheWorkerVersion = 'v1.0.0';

export const botName = import.meta.env.VITE_BOT_NAME ?? 'SETUP_BOTNAME_IN_ENV';

export const releaseStage = import.meta.env.VITE_APP_ENV ?? 'development';

export const appVersion = import.meta.env.VITE_APP_VERSION ?? '0.0.1';

export const TON_FUN_MASTER_ADDRESS =
    import.meta.env.VITE_APP_TON_FUN_MASTER_ADDRESS ??
    'EQBJnZJuIQszBYGynPeuCjq5YpWWYlScwFyGiZBm3fHl_7Mu';

export const TON_FUN_API_URL =
    import.meta.env.VITE_APP_TON_FUN_API_URL ?? 'https://test.tonfun.tech/api/v1';

export const FEE_ADDRESS = Address.parse(
    import.meta.env.VITE_APP_FEE_ADDRESS ?? 'UQCziNJPlKJz82CYgDm5q2lGkGMfSJZ01ZrQdXHiBEJwHlIY'
);

export const RPC_NODE_URL = import.meta.env.VITE_APP_RPC_NODE_URL ?? 'https://ton-mainnet.core.chainstack.com/55d2a7c1db86a6ee8852147c784c41c8/api/v2/jsonRPC';

export const TON_API_KEY = '';

export const MIN_BUY_AMOUNT = releaseStage === 'production' ? '0.1' : '0.0000001';

export const STONFI_FEE_ADDRESS = Address.parse(
    import.meta.env.VITE_APP_STONFI_FEE_ADDRESS ??
        'UQCuQbRcxns-XPjNNz-_dEG0VIZumAmjE6LlRFv_uPUGuHS2'
);

export const OLD_MASTER_ADDRESS =
    '0:9fde2850ec933aab0e1562cf9ba472dd6fc030109e558e4fc62821e086bc961f';

export const IS_TON_KEEPER = Boolean(window.tonkeeper);
export const IS_TG = Boolean(WebApp.initData);
