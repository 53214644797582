import { Button } from 'common/components/button.tsx';
import { useConnectionModal } from 'tonConnect/tonConnect.store.ts';
import { useTranslation } from 'i18n';

type Props = {
    children?: React.ReactNode;
};

export const OpenTonConnectModal = ({ children }: Props) => {
    const { t } = useTranslation();
    const { open } = useConnectionModal();
    const openTonConnectModal = () => {
        open();
    };

    if (children) {
        return (
            <button className="w-fit" onClick={openTonConnectModal}>
                {children}
            </button>
        );
    }

    return (
        <Button className="border-red" onClick={openTonConnectModal}>
            {t('connect-wallet')}
        </Button>
    );
};
