import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { useTranslation } from 'i18n';
import { unlockTokens, useCoinById } from 'coin/coin.store.ts';
import WebApp from '@twa-dev/sdk';
import { Address } from '@ton/core';
import { Drawer } from 'common/components/drawer.tsx';
import { useEffect, useRef, useState } from 'preact/hooks';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { useTon } from 'main/main.store.ts';
import { useOperationDrawer } from 'coin/components/operationInProcess.tsx';
import classNames from 'classnames';
import { useTonConnectAppName } from 'tonConnect/tonConnect.store.ts';
import { openExternalLink } from 'common/utils/openExternalLink.ts';
import { stonfiRefLink } from 'bclSdkOriginal/utils.ts';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';

export const UnlockTokens = () => {
    const { t } = useTranslation();
    const { coin, isUnlocked, coinBalance } = useCoinById();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { price } = useTon();
    const appName = useTonConnectAppName();

    const { OperationDrawer, open } = useOperationDrawer();

    const redirectToPocketFi = () => {
        if (!coin) {
            return;
        }
        WebApp.openTelegramLink(
            `https://t.me/pocketfi_bot/Pocketswap?startapp=swapTo-${btoa(Address.parse(coin.address).toString({ urlSafe: true, bounceable: false }))}-chainId-TON`
        );
    };

    const redirectToStonfi = () => {
        if (!coin) {
            return;
        }
        openExternalLink(stonfiRefLink(Address.parse(coin.address)));
    };

    const onUnlock = async () => {
        await unlockTokens();
        open();
    };

    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const scrollToBuyButton = () => {
        buttonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    useEffect(() => {
        if (!buttonRef.current) {
            return;
        }
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsButtonVisible(entry.isIntersecting);
            },
            { threshold: 0.5 }
        );
        observer.observe(buttonRef.current as Element);
        return () => {
            observer.disconnect();
        };
    }, [buttonRef.current]);

    if ((isUnlocked === null && coinBalance === undefined) || !tonConnectUI.connected) {
        return null;
    }

    if (isUnlocked || coinBalance === 0) {
        if (
            // @ts-ignore
            tonConnectUI.wallet?.appName === 'pocketfi' ||
            tonConnectUI.wallet?.device?.appName === 'pocketfi' ||
            appName === 'pocketfi'
        ) {
            return (
                <BigColorButton className="whitespace-nowrap" onClick={redirectToPocketFi}>
                    {t('token.swap-on-pocketfi')}
                </BigColorButton>
            );
        }

        return (
            <BigColorButton className="whitespace-nowrap" onClick={redirectToStonfi}>
                <img src="/stonfi.svg" alt="" className="w-4 inline mr-2 -mt-1" />
                {t('token.swap-on-stonfi')}
            </BigColorButton>
        );
    }

    return (
        <>
            <BigColorButton onClick={() => setIsDrawerOpen(true)} ref={buttonRef}>
                {t('token.unlock-tokens').toUpperCase()}
            </BigColorButton>
            <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} position="bottom">
                <div className="flex flex-col items-center pb-4">
                    <TextWithShadow className="mb-3 italic">
                        {t('token.unlock-tokens.title').toUpperCase()}
                    </TextWithShadow>
                    <p
                        className="font-normal text-white text-center mb-10 px-4"
                        dangerouslySetInnerHTML={{
                            __html: t('token.unlock-tokens.description')
                                .replace(/<>(.*?)<\/>/gi, '<span class="font-bold">$1</span>')
                                .replace(/\n/g, '<br />')
                                .replace('{totalLiquidity}', `${1500 * price}`),
                        }}
                    />
                    <BigColorButton className="h-12" onClick={onUnlock}>
                        {t('token.unlock-tokens').toUpperCase()}
                    </BigColorButton>
                </div>
            </Drawer>
            {OperationDrawer}
            <button
                className={classNames(
                    'fixed bottom-[100px] left-7 z-30 bg-gradient-to-r from-[#3A8200] via-[#91CB00] to-[#3A8200] rounded-xl create-token-shadow w-[calc(100%-3.5rem)]',
                    { hidden: isButtonVisible }
                )}
                onClick={scrollToBuyButton}
            >
                <div className="w-full bg-gradient-to-b from-transparent via-white/40 to-transparent py-3 flex items-center justify-center gap-1.5">
                    <TextWithShadow className="italic">
                        {t('token.unlock-tokens').toUpperCase()}
                    </TextWithShadow>
                </div>
            </button>
        </>
    );
};
