import { useEffect } from 'preact/hooks';
import { useTonProof } from 'tonkeeper/tonkeeper.store.ts';
import { useAppVisibleStatus } from 'app.store.ts';

export const useAutoFetch = (callback: () => void, interval?: number, delay?: number) => {
    const { isAuthorized } = useTonProof();
    const isAppVisible = useAppVisibleStatus();
    useEffect(() => {
        if (!delay) {
            callback();
        } else {
            setTimeout(callback, delay);
        }

        if (interval && isAppVisible) {
            let intervalId: ReturnType<typeof setInterval>;
            let timeoutId: ReturnType<typeof setTimeout>;
            if (!delay) {
                intervalId = setInterval(callback, interval);
            } else {
                timeoutId = setTimeout(() => {
                    intervalId = setInterval(callback, interval);
                }, delay);
            }

            return () => {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
            };
        }
    }, [isAuthorized, isAppVisible]);
};
