import { FC, PropsWithChildren } from 'preact/compat';
import { Header } from './header.tsx';
import { useTonProof } from 'tonkeeper/tonkeeper.store.ts';
import { IS_TG } from 'common/constants';
import classNames from 'classnames';
import { OpenTonConnectModal } from 'tonConnect/openTonConnectModal.tsx';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { useTranslation } from 'i18n';

type MainLayoutProps = {
    header?: PropsWithChildren['children'];
    color?: string;
    footer?: PropsWithChildren['children'];
};

export const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = ({
    children,
    header,
    color,
    footer,
}) => {
    const { t } = useTranslation();
    const { isAuthorized } = useTonProof();
    const shouldBlurMainScreen = !IS_TG && !isAuthorized;
    return (
        <div
            className="bg-new-background-primary h-dvh w-screen fixed top-0 left-0 overflow-y-auto overflow-x-hidden scroll-pt-16"
            style={{ backgroundColor: color }}
        >
            <Header />
            {header}
            <div
                className={classNames('p-2.5', {
                    'blur-lg': shouldBlurMainScreen,
                })}
            >
                {children}
            </div>
            {shouldBlurMainScreen ? (
                <div className="absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-5">
                    <div className="mb-5">
                        <div className="bg-white rounded-2xl border-2 border-black mb-4 p-4 text-sm relative shadow-[1.83px_1.83px_3.66px_0px_#000000]">
                            {t('intro.hello-my-new-fren')}
                            <br />
                            {'<3 <3 <3'}
                            <br />
                            {t('intro.please-connect-wallet')}
                            <div className="absolute top-full left-[13px] border-t-black border-t-[32px] border-l-[27px] border-l-transparent" />
                            <div className="absolute top-[calc(100%-1px)] left-4 border-t-white border-t-[28px] border-l-[22px] border-l-transparent" />
                        </div>
                        <img src="/dog-helper.png" alt="" className="w-32 ml-5" />
                    </div>
                    <OpenTonConnectModal>
                        <BigColorButton variant="yellow" className="min-w-[280px] w-[280px] h-16">
                            {t('connect-wallet').toUpperCase()}
                        </BigColorButton>
                    </OpenTonConnectModal>
                    <div className="flex items-center justify-center w-full mt-2">
                        <img src="/arrow_right.gif" alt="arrow" className="w-20 h-20 -rotate-90" />
                        <img src="/arrow_right.gif" alt="arrow" className="w-20 h-20 -rotate-90" />
                    </div>
                </div>
            ) : null}
            {footer}
        </div>
    );
};
