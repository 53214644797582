import { Route } from 'wouter-preact';
import { initTonConnect } from './tonConnect/init.ts';
import { MainLayout } from 'common/components/mainLayout.tsx';
import { MainPage } from 'main/main.page.tsx';
import { useEffect } from 'preact/hooks';
import { TokenPage } from 'coin/token.page.tsx';
import { TokenPageMenu } from 'coin/tokenPageMenu.tsx';
import { TokenTradeTab } from 'coin/tabs/tokenTradeTab.tsx';
import { TokenChartTab } from 'coin/tokenChartTab.tsx';
import { TokenAboutTab } from 'coin/tabs/tokenAboutTab.tsx';
import { onTonConnectStatusChange, tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchTonPrice } from 'main/main.store.ts';
import { NotificationRow } from 'main/notificationRow.tsx';
import { FrensPage } from 'frens/frens.page.tsx';
import { fetchMe, useUser } from 'user/user.store.ts';
import { WalletConnectModal } from 'tonConnect/walletConnectModal.tsx';
import { parseStartParam } from 'common/utils/parseStartParam.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { CreateCoinPage } from 'createToken/createCoin.page.tsx';
import { initPocketFiBridge } from 'tonConnect/pocketfi.ts';
import { LoadingPage } from 'preloading/loading.page.tsx';
import { useIsTonConnectReady, useRestoreTonConnect } from 'tonConnect/tonConnect.store.ts';
import WebApp from '@twa-dev/sdk';
import { CabalArenaPage } from 'cabalArena/cabalArena.page.tsx';
import { expandWebApp } from 'common/utils/tgUtilt.ts';
import { initTonProofPayload, onTonProofConnect } from 'tonkeeper/hooks.ts';
import { IS_TON_KEEPER } from 'common/constants';
import { parseUtmMarks } from 'common/utils/parseUtmMarks.ts';
import isEmpty from 'lodash-es/isEmpty';
import { gtag } from 'gtag.ts';
import { LinkGeneratorPage } from 'linkGenerator/linkGenerator.page.tsx';
import { getCoinIdByAddress } from 'coin/coin.store.ts';
import { setIsVisible } from 'app.store.ts';

initTonConnect();
initPocketFiBridge();
initTonProofPayload();

export function App() {
    const { isFetched } = useUser();
    const isReady = useIsTonConnectReady();

    useAutoFetch(fetchMe);

    useRestoreTonConnect();

    useEffect(() => {
        const onVisibilityChange = () => {
            setIsVisible(document.visibilityState === 'visible');
        };

        document.addEventListener('visibilitychange', onVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, []);

    if (window.location.pathname === '/link-generator') {
        return <LinkGeneratorPage />;
    }

    if ((!isFetched && !IS_TON_KEEPER) || !isReady) {
        return <LoadingPage />;
    }

    return <AppRouter />;
}

function AppRouter() {
    useEffect(() => {
        tonConnectUI.onStatusChange(
            async (wallet) => {
                if (IS_TON_KEEPER) {
                    await onTonProofConnect(wallet);
                }
                await onTonConnectStatusChange(wallet);
            },
            (err) => {
                if (err) {
                    console.log(err);
                    tonConnectUI.disconnect();
                }
            }
        );

        const savedInitData = sessionStorage.getItem('initData');

        if (savedInitData !== WebApp.initData) {
            sessionStorage.setItem('initData', WebApp.initData);
            const startParams = parseStartParam(WebApp.initDataUnsafe.start_param);

            if (startParams?.params) {
                const utmMarks = parseUtmMarks(startParams.params);
                if (!isEmpty(utmMarks)) {
                    gtag('utm_data', utmMarks);
                }
                if ('coinId' in startParams.params) {
                    navigation.tokenTrade(startParams.params.coinId)();
                } else if ('address' in startParams.params) {
                    getCoinIdByAddress(startParams.params.address);
                }
            }
        }

        expandWebApp();
    }, []);

    useAutoFetch(fetchTonPrice, 60000);

    // useCabalArenaPage();

    return (
        <>
            <Route path="/">
                <MainLayout header={<NotificationRow />}>
                    <MainPage />
                </MainLayout>
            </Route>

            <Route path="/create">
                <CreateCoinPage />
            </Route>

            <Route path="/token/:id/trade">
                {(params) => (
                    <MainLayout footer={<TokenPageMenu tokenId={params.id} />}>
                        <TokenPage tokenId={params.id}>
                            <TokenTradeTab tokenId={params.id} />
                        </TokenPage>
                    </MainLayout>
                )}
            </Route>

            <Route path="/token/:id/about">
                {(params) => (
                    <MainLayout footer={<TokenPageMenu tokenId={params.id} />}>
                        <TokenPage tokenId={params.id}>
                            <TokenAboutTab tokenId={params.id} />
                        </TokenPage>
                    </MainLayout>
                )}
            </Route>

            <Route path="/token/:id/chart">
                {(params) => (
                    <MainLayout footer={<TokenPageMenu tokenId={params.id} />}>
                        <TokenPage tokenId={params.id}>
                            <TokenChartTab />
                        </TokenPage>
                    </MainLayout>
                )}
            </Route>

            <Route path="/frens">
                <MainLayout color="#1A1A1A">
                    <FrensPage />
                </MainLayout>
            </Route>

            <Route path="/cabal-arena">
                <CabalArenaPage />
            </Route>

            <WalletConnectModal />
        </>
    );
}
