import { Drawer } from 'common/components/drawer.tsx';
import { useTranslation } from 'i18n';
import { useCallback } from 'preact/compat';
import { useState } from 'preact/hooks';

type OperationInProcessProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const OperationInProcess = ({ isOpen, onClose }: OperationInProcessProps) => {
    const { t } = useTranslation();

    return (
        <Drawer open={isOpen} onClose={onClose} position="bottom">
            <div className="flex flex-col items-center mx-auto mt-auto mb-9">
                <img src="/goose.gif" alt="" className="w-14 h-14" />
                <p className="text-md text-white font-bold">{t('token.operationInProgress')}</p>
            </div>
        </Drawer>
    );
};

export const useOperationDrawer = () => {
    const [isOpen, setOpen] = useState(false);

    const close = useCallback(() => setOpen(false), []);
    const open = useCallback(() => {
        setOpen(true);

        setTimeout(() => {
            setOpen(false);
        }, 10000);
    }, [close]);

    return {
        open,
        OperationDrawer: <OperationInProcess isOpen={isOpen} onClose={close} />,
    };
};
