type ParsedUtmMarks = {
    utm_source?: string;
    utm_medium?: string;
    utm_content?: string;
    utm_term?: string;
    utm_campaign?: string;
};

export const parseUtmMarks = (params: object): ParsedUtmMarks => {
    let result: ParsedUtmMarks = {};

    if ('utm_source' in params && typeof params.utm_source === 'string') {
        result.utm_source = params.utm_source;
    }
    if ('utm_medium' in params && typeof params.utm_medium === 'string') {
        result.utm_medium = params.utm_medium;
    }
    if ('utm_content' in params && typeof params.utm_content === 'string') {
        result.utm_content = params.utm_content;
    }
    if ('utm_term' in params && typeof params.utm_term === 'string') {
        result.utm_term = params.utm_term;
    }
    if ('utm_campaign' in params && typeof params.utm_campaign === 'string') {
        result.utm_campaign = params.utm_campaign;
    }

    return result;
};
