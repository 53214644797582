import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { TokenMarketCap } from 'coin/components/tokenMarketCap.tsx';
import { TokenCreatedBy } from 'coin/components/tokenCreatedBy.tsx';
import { Skeleton } from 'common/components/skeleton.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { getCoinMarketCap } from 'coin/utils.ts';
import { useTon } from 'main/main.store.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { useTranslation } from 'i18n';
import { useTonConnectConnectionStatus } from 'tonConnect/tonConnect.store.ts';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { WalletConnectionWrapper } from 'tonConnect/walletConnectionWrapper.tsx';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { PriceChange } from 'coin/components/priceChange.tsx';
import { HoldersAmount } from 'coin/holders/holdersAmount.tsx';
import { NotesAmount } from 'coin/communityNotes/notesAmount.tsx';
import { Progress } from 'common/components/progress.tsx';
import { fetchKingOfHill, useKingOfHill } from 'main/kingOfHill.store.ts';
import { TickerLabel } from 'coin/components/tickerLabel.tsx';

export const KingOfHill = () => {
    const { king } = useKingOfHill();
    const { price } = useTon();
    const { t } = useTranslation();
    const isWalletConnected = useTonConnectConnectionStatus();

    useAutoFetch(fetchKingOfHill);

    if (!king && isWalletConnected) {
        return <Skeleton size="lg" height={200} />;
    }

    const navigateToKing = () => {
        if (king && isWalletConnected) {
            navigation.tokenTrade(king.id)();
        }

        if (!isWalletConnected) {
            tonConnectUI.openModal();
        }
    };

    return (
        <div
            className={`border-2 border-blue bg-[url('/windows_xp.webp')] bg-center bg-cover ${isWalletConnected ? 'p-4' : 'px-4 py-10'}`}
            onClick={navigateToKing}
        >
            {!isWalletConnected ? (
                <div className="flex flex-col items-center gap-5">
                    <TextWithShadow className="!text-base italic !text-orange">
                        {t('main.create-your-own-memecoin').toUpperCase()}
                    </TextWithShadow>
                    <TextWithShadow className="text-xl italic !text-orange">
                        {t('main.in-30-sec').toUpperCase()}
                    </TextWithShadow>
                    <WalletConnectionWrapper containerClassName="w-44">
                        <BigColorButton
                            icon={
                                <img
                                    src="/gem.gif"
                                    alt="gem"
                                    className="w-full aspect-square absolute"
                                />
                            }
                            onClick={navigation.createToken}
                            fontSize="sm"
                        >
                            {t('main.start').toUpperCase()}
                        </BigColorButton>
                    </WalletConnectionWrapper>
                </div>
            ) : king ? (
                <>
                    <TextWithShadow
                        shadowWidth={1}
                        className="!text-dark-yellow italic text-center"
                        tag="p"
                    >
                        {t('main.kingOfTheHill').toUpperCase()}
                    </TextWithShadow>
                    <div className="flex flex-col gap-2 p-3 border-2 bg-[#005AE0] bg-gradient-to-b from-transparent via-white/30 to-transparent">
                        <div className="flex items-center gap-2">
                            <div className="relative">
                                <img
                                    src={king.imageUrl}
                                    alt={king.imageName}
                                    className="min-w-[72px] w-[72px] max-h-[72px] object-cover object-center"
                                />
                            </div>
                            <div className="w-full flex flex-col gap-1">
                                <div className="flex items-center gap-1 text-white">
                                    <span className="text-sm font-normal break-word">
                                        {king.name}
                                    </span>
                                    <PriceChange
                                        value={parseFloat(king.priceChange1H)}
                                        colors={{
                                            positive: 'text-oxid-green',
                                            negative: 'text-red',
                                        }}
                                    />
                                    <TickerLabel className="ml-auto">{king.symbol}</TickerLabel>
                                </div>
                                <TokenMarketCap
                                    isNew
                                    value={getCoinMarketCap(king, price)}
                                    className="!text-oxid-green"
                                    blockClassName="!bg-oxid-green !text-[#005AE0]"
                                />
                                <div className="flex items-center gap-3">
                                    <HoldersAmount
                                        value={parseFloat(king.holderAmount)}
                                        className="text-white"
                                        icon={
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g opacity="0.48">
                                                    <path
                                                        d="M3 12.5C3 10.0147 5.01472 8 7.5 8C9.98528 8 12 10.0147 12 12.5V13H3V12.5Z"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        x="5"
                                                        y="3"
                                                        width="5"
                                                        height="5"
                                                        rx="2.5"
                                                        fill="white"
                                                    />
                                                    <path
                                                        d="M11.0004 5.50061C11.0004 4.52097 10.5979 3.63531 9.94922 3H11.2422C11.7211 3.71518 12.0004 4.57529 12.0004 5.50061C12.0004 6.19116 11.8448 6.84543 11.5668 7.43021C13.0502 8.62152 13.9997 10.4503 13.9997 12.5009V13H12.9997V12.5009C12.9997 10.4491 11.8763 8.65979 10.2109 7.71445C10.4216 7.45679 10.5962 7.16855 10.7271 6.85742C10.727 6.85733 10.7272 6.85751 10.7271 6.85742C10.9028 6.44009 11.0004 5.98181 11.0004 5.50061Z"
                                                        fill="white"
                                                    />
                                                </g>
                                            </svg>
                                        }
                                    />
                                    <NotesAmount
                                        value={parseFloat(king.noteAmount)}
                                        className="text-white"
                                        icon={
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g opacity="0.48">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M6.5 3C4.01472 3 2 5.01472 2 7.5C2 9.98528 4.01472 12 6.5 12H8.5C8.80116 12 9.0954 11.9704 9.37999 11.914L13 13L12.0073 10.3196C12.6283 9.54816 13 8.56748 13 7.5C13 5.01472 10.9853 3 8.5 3H6.5Z"
                                                        fill="white"
                                                        style="fill:white;fill-opacity:1;"
                                                    />
                                                </g>
                                            </svg>
                                        }
                                    />
                                </div>
                                <TokenCreatedBy className="text-white">
                                    {king.user.username}
                                </TokenCreatedBy>
                            </div>
                        </div>
                        <Progress
                            progress={parseFloat(king.bondingCurveProgressTon)}
                            variant="green"
                            className="!bg-[#305294] !text-white"
                        />
                    </div>
                </>
            ) : (
                <Skeleton size="lg" />
            )}
        </div>
    );
};
