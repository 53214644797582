import { BaseEntityStore } from 'common/constants/types.ts';
import { $coin, Coin, fetchCoinBalanceById } from 'coin/coin.store.ts';
import { action, computed, map } from 'nanostores';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { uniqBy } from 'lodash-es';
import { useStore } from '@nanostores/preact';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { fetchTonBalance } from 'user/user.store.ts';

export type CoinTransaction = {
    id: string;
    coinId: Coin['id'];
    user: {
        id: string;
        username: string;
    } | null;
    actorAddress: string;
    tonFees: string;
    totalSupply: string;
    refId: number;
    openPrice: string;
    closePrice: string;
    lt: string;
    txUtime: string;
    txHash: string;
    createdAt: string;
    type: 'buy' | 'sell';
    coinsAmount: string;
    tonAmount: string;
};

interface CoinTransactionsStore extends BaseEntityStore {
    transactions: CoinTransaction[];
    transactionsCursor?: string;
}

export const $coinTransactions = map<CoinTransactionsStore>({
    isFetched: false,
    isLoading: false,
    transactions: [],
    transactionsCursor: undefined,
});

// actions

export const fetchCoinTransactions = action(
    $coinTransactions,
    'fetchCoinTransactions',
    async (store, coinId: Coin['id']) => {
        store.setKey('isLoading', true);

        try {
            const { transactionsCursor, transactions } = store.get();

            const search = new URLSearchParams({
                limit: '10',
                ...(transactionsCursor
                    ? {
                          cursor: transactionsCursor,
                      }
                    : {}),
            });

            const response = await authorizedFetch(
                routes.coinByIdTransaction(coinId) + '?' + search.toString()
            );

            if (response.ok) {
                const data = (await response.json()) as {
                    transactions: CoinTransaction[];
                    nextCursor: string;
                };

                store.setKey(
                    'transactions',
                    uniqBy([...transactions, ...data.transactions], 'txHash')
                );
                store.setKey('transactionsCursor', data.nextCursor);
                store.setKey('isFetched', true);
            }
        } catch (e) {
            console.error('fetchCoinTransactions error', e);
        } finally {
            store.setKey('isLoading', false);
        }
    }
);

export const pullCoinTransactions = action(
    $coinTransactions,
    'pullCoinTransactions',
    async (store, coinId: Coin['id']) => {
        try {
            const { transactions } = store.get();
            const { coin } = $coin.get();

            if (coin?.id !== coinId) {
                return;
            }

            const search = new URLSearchParams({
                limit: '9',
            });

            const response = await authorizedFetch(
                routes.coinByIdTransaction(coinId) + '?' + search.toString()
            );

            if (response.ok) {
                const data = (await response.json()) as {
                    transactions: CoinTransaction[];
                    nextCursor: string;
                };

                if ($coin.get().coin?.id !== coinId) {
                    return;
                }

                data.transactions.forEach((transaction) => {
                    const isTransactionInStore = transactions.some(
                        (t) => t.txHash === transaction.txHash
                    );
                    if (
                        transaction.actorAddress === tonConnectUI.account?.address &&
                        !isTransactionInStore
                    ) {
                        fetchCoinBalanceById(coinId);
                        fetchTonBalance();
                    }
                });

                store.setKey(
                    'transactions',
                    uniqBy([...data.transactions, ...transactions], 'txHash')
                );
            }
        } catch (e) {
            console.log('error on pullCoinTransactions', e);
        }
    }
);

export const clearTransactions = action($coinTransactions, 'clearTransactions', (store) => {
    store.setKey('transactions', []);
    store.setKey('transactionsCursor', undefined);
    store.setKey('isLoading', false);
    store.setKey('isFetched', false);
});

// selectors

const selectTransactions = computed($coinTransactions, (store) => ({
    ...store,
    canFetchMore: Boolean(store.transactionsCursor),
    isFetching: store.isLoading && !store.isFetched,
}));

// hooks

export const useCoinTransactions = () => useStore(selectTransactions);
