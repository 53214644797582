import { useTranslation } from 'i18n';
import { capitalize } from 'lodash-es';

type TransactionStatus = 'sell' | 'buy' | 'deploy';

export const TransactionStatus = ({ status }: { status: TransactionStatus }) => {
    const { t } = useTranslation();

    const mapStatusToColor = {
        sell: 'text-bloody-red',
        buy: 'text-oxid-green',
        deploy: 'text-dark-yellow',
    };
    return (
        <span className={`text-xs ${mapStatusToColor[status]} font-medium`}>
            {capitalize(t(`token.transactions.${status}`))}
        </span>
    );
};
