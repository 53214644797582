import { useTranslation } from 'i18n';
import { useUser } from 'user/user.store.ts';
import { fetchReferralsStat, useReferralsStats } from 'frens/frens.store.ts';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { generateReferralLink, openShareWithFriendsLink } from 'frens/utils.ts';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { CopyButton } from 'common/components/copyButton.tsx';
import { ReferralsStatsTable } from 'frens/referralsStatsTable.tsx';
import { Skeleton } from 'common/components/skeleton.tsx';
import { Card } from 'common/components/card.tsx';
import { FrensBonus } from 'frens/frens.page.tsx';

export const InviteFrens = () => {
    const { t } = useTranslation();
    const { user } = useUser();
    const { stats } = useReferralsStats();

    useAutoFetch(fetchReferralsStat);

    const referralLink = generateReferralLink({ user });
    return (
        <>
            <div className="flex flex-col items-center w-full gap-5 mb-3">
                <img
                    src="/frens_icon_2.png"
                    alt="frens"
                    className="w-[4.5rem] aspect-square mt-3"
                />
                <div className="w-full flex flex-col gap-2 px-5">
                    <TextWithShadow className="italic text-center">
                        {t('frens.title').toUpperCase()}
                    </TextWithShadow>
                    {/*<p className="text-xs text-white text-center">{t('frens.referral-fees')}</p>*/}
                </div>
            </div>
            <RuleCard percent={23} text={t('frens.level1')} />
            <RuleCard percent={5} text={t('frens.level2')} />
            <RuleCard percent={2} text={t('frens.level3')} />
            <div className="flex items-center gap-2 w-full mb-5">
                <BigColorButton
                    variant="yellow"
                    icon={<img src="/arrow_right.gif" alt="open" className="w-6" />}
                    onClick={() =>
                        openShareWithFriendsLink(referralLink, t('frens.invite.message'))
                    }
                >
                    {t('frens.inviteButton').toUpperCase()}
                </BigColorButton>
                <CopyButton text={referralLink}>
                    <BigColorButton
                        variant="yellow"
                        className="aspect-square !w-12 !min-w-[3rem]"
                        textClassName="flex items-center justify-center"
                    >
                        <img src="/content_copy.svg" alt="copy" className="w-6 aspect-square" />
                    </BigColorButton>
                </CopyButton>
            </div>
            {stats ? <ReferralsStatsTable stats={stats} /> : <Skeleton size="lg" height="96px" />}
            {/*<DetailedFrensList />*/}
        </>
    );
};

const RuleCard = ({ percent, text }: { percent: number; text: string }) => {
    return (
        <Card className="!bg-blue !p-3 flex items-center gap-2">
            <FrensBonus percent={percent} />
            <p className="text-white text-sm">{text}</p>
        </Card>
    );
};
