import { useTranslation } from 'i18n';

export const FirstNote = () => {
    const { t } = useTranslation();
    return (
        <div className="flex items-center justify-between">
            <img src="/crown.png" alt="" className="w-4" />
            <p className="text-xs font-normal leading-4 text-yellow">
                {t('community-notes.your-note-will-be-first')}
            </p>
            <img src="/crown.png" alt="" className="w-4" />
        </div>
    );
};
