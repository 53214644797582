import { normalizedToFixed } from 'common/utils/normalizedToFixed.ts';
import classNames from 'classnames';

type Props = {
    value: number;
    className?: string;
    colors?: {
        positive?: string;
        negative?: string;
        neutral?: string;
    };
    arrow?: boolean;
};

const defaultColors = {
    positive: 'text-dark-green',
    negative: 'text-dark-red',
    neutral: 'text-white',
};

export const PriceChange = ({ value, className, colors = defaultColors, arrow }: Props) => {
    if (Number.isNaN(value)) {
        return null;
    }

    const finalColors = { ...defaultColors, ...colors };

    const isPositive = value > 0;
    const isNeutral = value === 0;
    const color = isPositive
        ? finalColors.positive
        : isNeutral
          ? finalColors.neutral
          : finalColors.negative;
    const symbol = isPositive ? '+' : '';

    const priceChange = `${symbol}${normalizedToFixed(value, 1)}`;
    const normalizePriceSymbol = arrow ? priceChange.slice(1) : priceChange;

    return (
        <div className={`flex items-center gap-0.5 ${color}`}>
            {arrow && (
                <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={classNames({ 'transform rotate-180': !isPositive })}
                >
                    <path d="M2 6L6 2L10 6" stroke="currentColor" stroke-width="1.2" />
                    <path d="M6 2V12" stroke="currentColor" stroke-width="1.2" />
                </svg>
            )}
            <span className={`${color} text-2xs font-medium ${className}`}>
                {normalizePriceSymbol}%
            </span>
        </div>
    );
};
