import { Card } from 'common/components/card.tsx';
import dayjs from 'dayjs';
import { useState } from 'preact/hooks';
import { Drawer } from 'common/components/drawer.tsx';
import { navigation } from 'common/utils/routeUtils.ts';
import { Coin } from 'coin/coin.store.ts';
import {
    CommunityNote,
    fetchCommunityNotes,
    setSortType,
    useCommunityNotes,
} from 'coin/communityNotes/communityNotes.store.ts';
import { useTranslation } from 'i18n';
import classNames from 'classnames';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import WebApp from '@twa-dev/sdk';
import { Skeleton } from 'common/components/skeleton.tsx';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { getRandomAvatar } from 'common/utils/getRandomAvatar.ts';
import { AddYourNote } from 'coin/communityNotes/addNewNote.tsx';
import { FirstNote } from 'coin/communityNotes/firstNote.tsx';
import { LikesPanel } from 'coin/communityNotes/likesPanel.tsx';
import { useScrollToFetchData } from 'common/hooks/useScrollLazyLoad.ts';

export const CommunityNotesOnTradingTab = ({
    tokenId,
    count,
}: {
    tokenId: Coin['id'];
    count: number;
}) => {
    const { t } = useTranslation();
    const { notes, isFetched } = useCommunityNotes();

    useAutoFetch(() => fetchCommunityNotes(tokenId, 'likeCount'), 60000);

    if (!isFetched) {
        return <Skeleton size="lg" />;
    }

    return (
        <Card className="!bg-blue !p-1">
            <div className="w-full h-full bg-heavy-blue p-3 flex flex-col gap-4">
                <div className="flex items-center justify-between">
                    <h3 className="text-base text-white-gray">{t('community-notes')}</h3>
                    <button
                        className="flex items-center gap-2 text-xs text-blue-sky mt-1"
                        onClick={navigation.tokenAbout(tokenId, 'community-notes')}
                    >
                        <span>{t('community-notes.view-all')}</span>
                        <span className="p-0.5 border border-blue leading-4">{count}</span>
                        <span className="text-sm">→</span>
                    </button>
                </div>
                {notes.length > 0 && <Note note={notes[0]} />}
                <AddYourNote coinId={tokenId} />
                {!notes.length && <FirstNote />}
            </div>
        </Card>
    );
};

export const CommunityNotesHeader = ({
    tokenId,
    count,
}: {
    tokenId: Coin['id'];
    count: number;
}) => {
    const { t } = useTranslation();
    const [sortDrawerOpen, setSortDrawerOpen] = useState(false);
    const { isFetched, notes, sortType, canFetchMore } = useCommunityNotes();
    const anchorRef = useScrollToFetchData(() => fetchCommunityNotes(tokenId));

    useAutoFetch(() => fetchCommunityNotes(tokenId));

    if (!isFetched) {
        return <Skeleton size="lg" />;
    }

    return (
        <>
            <div className="w-full h-full bg-heavy-blue p-3 flex flex-col gap-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        <h3 className="text-base text-white-gray">{t('community-notes')}</h3>
                        <span className="text-xs text-blue-sky mt-1">{count}</span>
                    </div>
                    <button className="flex items-center" onClick={() => setSortDrawerOpen(true)}>
                        <span className="text-xs text-blue-sky mt-1">
                            {t('sort-by')}{' '}
                            <strong>
                                {sortType === 'id'
                                    ? t('community-notes.sort-by.date')
                                    : t('community-notes.sort-by.likes')}
                            </strong>
                        </span>
                    </button>
                </div>
                <AddYourNote coinId={tokenId} />
                {notes.length > 0 ? (
                    <>
                        {notes.map((note) => (
                            <Note key={note.id} note={note} />
                        ))}
                        <div
                            className={classNames('w-full flex flex-col gap-3', {
                                [`hidden`]: !canFetchMore,
                            })}
                            ref={anchorRef}
                        >
                            <Skeleton size="lg" />
                            <Skeleton size="lg" />
                            <Skeleton size="lg" />
                            <Skeleton size="lg" />
                            <Skeleton size="lg" />
                        </div>
                    </>
                ) : (
                    <FirstNote />
                )}
            </div>
            <Drawer
                open={sortDrawerOpen}
                onClose={() => setSortDrawerOpen(false)}
                position="bottom"
            >
                <div className="flex flex-col items-center gap-3 pb-4">
                    <TextWithShadow className="italic mx-auto">
                        {t('sort-by').toUpperCase()}
                    </TextWithShadow>
                    <div className="bg-[#79A6FF] w-full border border-black border-b-4 px-3">
                        <button
                            className="w-full text-left text-xs leading-4 text-[#1A1A1A] py-2.5 flex items-center justify-between"
                            onClick={() => {
                                setSortType('likeCount');
                                fetchCommunityNotes(tokenId);
                                setSortDrawerOpen(false);
                            }}
                        >
                            <span>{t('community-notes.sort-by-button.likes')}</span>
                            {sortType === 'likeCount' && (
                                <img src="/check.svg" alt="" className="w-2.5" />
                            )}
                        </button>
                        <hr />
                        <button
                            className="w-full text-left text-xs leading-4 text-[#1A1A1A] py-2.5 flex items-center justify-between"
                            onClick={() => {
                                setSortType('id');
                                fetchCommunityNotes(tokenId);
                                setSortDrawerOpen(false);
                            }}
                        >
                            <span>{t('community-notes.sort-by-button.date')}</span>
                            {sortType === 'id' && <img src="/check.svg" alt="" className="w-2.5" />}
                        </button>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

const Note = ({ note }: { note: CommunityNote }) => {
    const { t } = useTranslation();
    return (
        <div className="flex items-start justify-between">
            <div className="flex items-start gap-2.5">
                <img
                    src={getRandomAvatar(note.username ?? 'anonymous')}
                    alt=""
                    className="w-9 h-9 aspect-square"
                />
                <div>
                    <div className="flex items-center gap-2">
                        <span
                            className="text-xs text-white"
                            onClick={() => {
                                if (note?.username) {
                                    WebApp.openTelegramLink('https://t.me/' + note?.username);
                                }
                            }}
                        >
                            @{note?.username ?? t('anonymous').toLowerCase()}
                        </span>
                        <span className="text-2xs font-medium text-blue-sky">
                            {dayjs.duration(dayjs(note.updatedAt).diff(dayjs())).humanize(true)}
                        </span>
                    </div>
                    <p className="font-normal text-sm text-white/80 mt-0.5 break-word w-full">
                        {note.content}
                    </p>
                </div>
            </div>
            <LikesPanel
                likeCount={note.likeCount}
                dislikeCount={note.dislikeCount}
                selfDislike={note.selfDislike}
                selfLike={note.selfLike}
                noteId={note.id}
            />
        </div>
    );
};
