import { InputLabel } from 'common/form/label.tsx';
import { CurrencyInput } from 'common/components/currencyInput.tsx';
import { Button } from 'common/components/button.tsx';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { Coin, sellCoins } from 'coin/coin.store.ts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { NumericString } from 'bclSdkOriginal/types.ts';
import { Address } from '@ton/core';
import { parseValue } from 'common/utils/parseValue.ts';
import { createValidator } from 'common/form/validate.ts';
import { z } from 'zod';
import { useTranslation } from 'i18n';
import { WalletConnectionWrapper } from 'tonConnect/walletConnectionWrapper.tsx';
import { bigPumpSdk } from 'bigPumpSdk/sdk.store.ts';
import classNames from 'classnames';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { useOperationDrawer } from 'coin/components/operationInProcess.tsx';
import { normalizedToFixed } from 'common/utils/normalizedToFixed.ts';
import debounce from 'lodash-es/debounce';
import { useSlippage } from 'coin/slippage/slippage.store.ts';
import { SlippageSettings } from 'coin/slippage/slippageSettings.tsx';
import { OLD_MASTER_ADDRESS } from 'common/constants';

type SellTokenWidgetProps = {
    coin: Coin;
    coinBalance: number;
};

const validationSchema = z.object({
    amount: z
        .string()
        .min(1, { message: 'validation.invalidNumber' })
        .refine(
            (value) => Number.isFinite(Number(value)) && !Number.isNaN(Number(value)),
            'validation.invalidNumber'
        ),
});

export const SellTokenWidget = ({ coin, coinBalance }: SellTokenWidgetProps) => {
    const [amount, setAmount] = useState('');
    const [coinsAmount, setCoinsAmount] = useState(['0', '0']);
    const [error, setError] = useState<string>('');
    const { t } = useTranslation();
    const [preset, setPreset] = useState<number | null>(null);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const scrollToBuyButton = () => {
        buttonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsButtonVisible(entry.isIntersecting);
            },
            { threshold: 0.5 }
        );
        observer.observe(buttonRef.current as Element);
        return () => {
            observer.disconnect();
        };
    }, [buttonRef.current]);

    const sellSlippage = useSlippage('sell');

    const getTonsForCoins = useCallback(
        async (amount: NumericString) => {
            if (!amount || !bigPumpSdk) {
                return;
            }

            const { amount: coins, minAmount } = await bigPumpSdk.estimateTonsForCoins(
                Address.parse(coin.address),
                amount,
                sellSlippage
            );

            setCoinsAmount([
                parseValue(coins, coin.decimals).toLocaleString('en', { maximumFractionDigits: 4 }),
                parseValue(minAmount, coin.decimals).toLocaleString('en', {
                    maximumFractionDigits: 4,
                }),
            ]);
        },
        [coin, sellSlippage]
    );

    const debouncedGetTonsForCoins = useMemo(
        () => debounce(getTonsForCoins, 500),
        [getTonsForCoins]
    );

    useEffect(() => {
        debouncedGetTonsForCoins(amount as NumericString);
    }, [amount, sellSlippage]);

    const { OperationDrawer, open } = useOperationDrawer();

    const validator = useMemo(() => createValidator(validationSchema), []);

    const validate = async (value: string) => {
        const errors = await validator({
            amount: value,
        });
        if (errors.amount) {
            setError(errors.amount);
            return false;
        }
        if (parseFloat(value) - coinBalance > 0.01) {
            setError(t('validation.notEnoughBalance'));
            return false;
        }
        setError('');
        return true;
    };

    const onSubmit = async () => {
        try {
            if (preset) {
                await sellCoins({
                    amount: String(coinBalance * preset) as NumericString,
                    coinAddress: coin.address,
                    slippagePercent: sellSlippage,
                    tonsAmount: coinsAmount[0],
                });
                setPreset(null);
            } else {
                const validationResult = await validate(amount);
                if (!validationResult) {
                    return;
                }
                open();
                await sellCoins({
                    amount: amount as NumericString,
                    coinAddress: coin.address,
                    slippagePercent: sellSlippage,
                    tonsAmount: coinsAmount[0],
                });
            }
            setAmount('');
        } catch (e) {
            console.log(e);
        }
    };

    const convertValueToNumberWithDot = () => {
        setAmount((prev) => {
            const replacedString = prev.replace(',', '.');
            validate(replacedString);
            return replacedString;
        });
    };

    const onAmountChange = (value: string) => {
        const normalizedValue = normalizedToFixed(parseFloat(value.replace(',', '.')), 4);
        if (normalizedValue === 'NaN') {
            setAmount('');
            return;
        }
        setAmount(normalizedValue);
    };

    const onCustomAmountChange = (value: string) => {
        setPreset(null);
        onAmountChange(value);
    };

    const setPresetAmount = (percent: number) => {
        if (!coinBalance) return;
        const delimiter = coin?.masterAddress === OLD_MASTER_ADDRESS ? 0.99 : 1;
        let amount = String((percent * coinBalance) / 100);
        if (percent === 100) {
            amount = String(delimiter * coinBalance);
            setPreset(delimiter);
        } else {
            setPreset(percent / 100);
        }
        onAmountChange(amount);
    };

    return (
        <div className="flex flex-col gap-3 ">
            <div className="flex flex-col gap-1.5">
                <div className="flex items-center justify-between w-full">
                    <InputLabel text={t('buy-sell-token-widget-amount')} />
                    <SlippageSettings type="sell" />
                </div>
                <CurrencyInput
                    currency={coin.symbol}
                    value={amount}
                    onChange={onCustomAmountChange}
                    containerClassName="!bg-blue"
                    balance={Intl.NumberFormat('en', {
                        notation: 'compact',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 4,
                    }).format(coinBalance)}
                    validationError={error}
                    onBlur={convertValueToNumberWithDot}
                />
            </div>
            <div className="flex items-center gap-1.5">
                {[25, 50, 75, 100].map((percent) => (
                    <Button
                        key={percent}
                        variant="blueGradient"
                        className="w-12 h-8 text-xs border-black flex-1"
                        onClick={() => setPresetAmount(percent)}
                    >
                        {percent}%
                    </Button>
                ))}
            </div>
            <p className="text-xs text-blue-sky whitespace-nowrap font-medium font-Wix text-center">
                {t('token.youWillReceive')} ~{coinsAmount[0]} TON
                <br />({t('min').toLowerCase()}: {coinsAmount[1]} TON)
            </p>
            <WalletConnectionWrapper containerClassName="w-full">
                <BigColorButton
                    variant="red"
                    onClick={onSubmit}
                    ref={buttonRef}
                    disabled={error !== '' || ((!amount || parseFloat(amount) === 0) && !preset)}
                >
                    {t('token.sell').toUpperCase()}
                </BigColorButton>
            </WalletConnectionWrapper>
            <button
                className={classNames(
                    'fixed bottom-[100px] left-7 z-30 bg-gradient-to-r from-[#C11638] via-[#E94B6B] to-[#C11638] rounded-xl create-token-shadow w-[calc(100%-3.5rem)]',
                    { hidden: isButtonVisible }
                )}
                onClick={scrollToBuyButton}
            >
                <div className="w-full bg-gradient-to-b from-transparent via-white/40 to-transparent py-3 flex items-center justify-center gap-1.5">
                    <TextWithShadow className="italic">
                        {t('token.sell').toUpperCase()}
                    </TextWithShadow>
                </div>
            </button>
            {OperationDrawer}
        </div>
    );
};
