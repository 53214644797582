import { useUser } from 'user/user.store.ts';
import { useCoinById } from 'coin/coin.store.ts';
import { Drawer } from 'common/components/drawer.tsx';
import { useState } from 'preact/hooks';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { useTranslation } from 'i18n';
import { FrensBonus } from 'frens/frens.page.tsx';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { generateReferralLink, openShareWithFriendsLink } from 'frens/utils.ts';
import { CopyButton } from 'common/components/copyButton.tsx';
import { Address } from '@ton/core';

export const GoldShareCoinButton = () => {
    const { t } = useTranslation();
    const { user } = useUser();
    const { coin } = useCoinById();

    const [open, setOpen] = useState(false);

    const referralLink = generateReferralLink({
        user,
        params: {
            address: coin?.address ? Address.parse(coin.address).toString() : '',
            coinId: coin?.id ?? '',
        },
    });

    return (
        <>
            <button className="border-2 border-black/50 relative" onClick={() => setOpen(true)}>
                <div className="flex items-center justify-center w-8 h-8 bg-yellow box-border border-2 border-b-orange border-r-orange border-t-sand border-l-sand">
                    <img src="/share_arrow.svg" alt="" />
                </div>
                <img
                    src="/coin.svg"
                    alt=""
                    className="w-5 h-5 absolute top-0 right-0 translate-x-1/2 -translate-y-1/2"
                />
            </button>
            <Drawer open={open} onClose={() => setOpen(false)} position="bottom">
                <div className="relative flex flex-col gap-5 mt-12">
                    <img
                        src="/frens-cat.png"
                        alt="cat"
                        className="absolute h-24 left-1/2 -translate-x-1/2 bottom-full"
                    />
                    <div className="bg-blue border-b-4 border-b-[#00359B] flex flex-col gap-5 px-2.5 py-5">
                        <TextWithShadow className="italic mx-auto">
                            {t('frens.invite-frens').toUpperCase()}
                        </TextWithShadow>
                        <div className="grid grid-cols-3">
                            <FrensRule percent={30} text={t('frens.drawer.level1')} />
                            <FrensRule percent={7} text={t('frens.drawer.level2')} />
                            <FrensRule percent={3} text={t('frens.drawer.level3')} />
                        </div>
                    </div>
                    <p className="font-normal text-white/80 text-center mb-5">
                        {t('frens.drawer.leaderboard')}
                    </p>
                    <div className="flex items-center gap-2 w-full mb-5">
                        <BigColorButton
                            variant="yellow"
                            onClick={() =>
                                openShareWithFriendsLink(referralLink, t('frens.invite.message'))
                            }
                        >
                            {t('frens.inviteButton').toUpperCase()}
                        </BigColorButton>
                        <CopyButton text={referralLink}>
                            <BigColorButton
                                variant="yellow"
                                className="aspect-square !w-12 !min-w-[3rem]"
                                textClassName="flex items-center justify-center"
                            >
                                <img
                                    src="/content_copy.svg"
                                    alt="copy"
                                    className="w-6 aspect-square"
                                />
                            </BigColorButton>
                        </CopyButton>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

const FrensRule = ({ percent, text }: { percent: number; text: string }) => {
    return (
        <div className="flex flex-col items-center gap-1">
            <FrensBonus percent={percent} size="big" />
            <p className="text-white text-xs font-normal text-center">{text}</p>
        </div>
    );
};
