import { Address, TonClient } from '@ton/ton';
import { RPC_NODE_URL } from 'common/constants';

export class TonSdk {
    public readonly ton: TonClient;

    constructor() {
        const ton = new TonClient({
            endpoint: RPC_NODE_URL,
        });

        this.ton = ton;
    }

    async getBalance(address: string) {
        return this.ton.getBalance(Address.parse(address));
    }
}
