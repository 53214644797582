import { normalizedToFixed } from 'common/utils/normalizedToFixed.ts';

type ProgressProps = {
    progress: number;
    className?: string;
    variant?: 'green' | 'default' | 'light-green';
    background?: 'white' | 'black';
};

export const Progress = ({
    progress,
    className,
    variant = 'default',
    background = 'white',
}: ProgressProps) => {
    if (variant === 'light-green') {
        return (
            <div className={`flex items-center gap-2`}>
                <div
                    className={`relative ${background === 'white' ? 'bg-black/15' : 'bg-white/85'} h-2 w-full ${className} overflow-hidden shadow-[1px_1px_2px_0px_#00000026_inset] border-b border-b-light-gray`}
                >
                    <div
                        className="relative bg-gradient-to-r from-[#3A8200] via-[#91CB00] to-[#3A8200]"
                        style={{ width: `${progress}%` }}
                    >
                        <div className="absolute top-0 left-0 h-1 bg-white/25 w-full" />
                        <div
                            className={`h-2 bg-gradient-to-b from-transparent via-white/20 to-transparent progress-shadow flex items-center justify-center shadow-[1px_1px_2px_0px_#00000026_inset]`}
                        />
                    </div>
                    <div
                        className="absolute top-0 h-1 bg-white/10 w-full"
                        style={{ left: `${progress}%` }}
                    />
                </div>
                <span
                    className={`text-xs font-medium ${background === 'white' ? 'text-black' : 'text-white'}`}
                >
                    {progress}%
                </span>
            </div>
        );
    }

    if (variant === 'green') {
        return (
            <div className={`relative bg-white/10 h-4 w-full ${className} overflow-hidden`}>
                <div
                    className="relative bg-gradient-to-r from-[#3A8200] via-[#91CB00] to-[#3A8200]"
                    style={{ width: `${progress}%` }}
                >
                    <div className="absolute top-0 left-0 h-1 bg-white/25 w-full" />
                    <div
                        className={`h-4 bg-gradient-to-b from-transparent via-white/20 to-transparent progress-shadow flex items-center justify-center`}
                    >
                        <span
                            className={`text-xs ${progress > 4 ? '' : 'text-white translate-x-full'}`}
                        >
                            {normalizedToFixed(progress, 2) || '0'}%
                        </span>
                    </div>
                </div>
                <div
                    className="absolute top-0 h-1 bg-white/10 w-full"
                    style={{ left: `${progress}%` }}
                />
            </div>
        );
    }

    return (
        <div className={`bg-[#DCDCDC] relative h-4 w-full ${className}`}>
            <div
                className="bg-light-blue h-4 bg-gradient-to-b from-transparent via-white/20 to-transparent progress-shadow"
                style={{ width: `${progress}%` }}
            />
            <span
                className={`absolute left-1.5 top-1/2 -translate-y-1/2 text-2xs ${progress > 4 ? 'text-white' : ''}`}
            >
                {normalizedToFixed(progress, 2)}%
            </span>
        </div>
    );
};
