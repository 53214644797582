import { Input } from 'common/components/input';
import { useEffect, useState } from 'preact/hooks';

type LinkParams = {
    utm_source?: string;
    utm_content?: string;
    utm_medium?: string;
};

export const LinkGeneratorPage = () => {
    const [link, setLink] = useState('');
    const [linkParams, setLinkParams] = useState<LinkParams>({});
    const [copied, setCopied] = useState(false);
    const [refCode, setRefCode] = useState('');

    const [linkToDecode, setLinkToDecode] = useState('');

    const updateLink = () => {
        const baseUrl = 'https://t.me/pocketfi_bot/bigpump';

        let resUrl = baseUrl;

        if (Object.keys(linkParams).length || refCode) {
            resUrl += '?startapp=';
        }

        if (refCode) {
            resUrl += `${refCode}`;
        }

        if (Object.keys(linkParams).length) {
            const resObject = {} as Record<string, string>;

            Object.keys(linkParams).forEach((key: string) => {
                if ((linkParams as Record<string, string>)[key]) {
                    resObject[key] = (linkParams as Record<string, string>)[key];
                }
            });

            resUrl += `-${btoa(JSON.stringify(linkParams))}`;
        }

        setLink(resUrl);
    };

    // Synchronize linkParams and refCode into the generated link
    useEffect(() => {
        updateLink();
    }, [linkParams, refCode]);

    const onChange = (key: keyof LinkParams | 'ref_code', value: string) => {
        if (key !== 'ref_code') {
            const updatedParams = { ...linkParams, [key]: value };
            setLinkParams(updatedParams);
        }

        if (key === 'ref_code') {
            setRefCode(value);
        }
    };

    const copyToClipboard = () => {
        if (link) {
            navigator.clipboard.writeText(link).then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
            });
        }
    };

    function decodeLink(link: string) {
        try {
            const part = link.split('?startapp=')[1];

            if (part.split('-').length > 1) {
                const [refCode, params] = part.split('-');

                const res = JSON.parse(atob(params));

                return JSON.stringify({ refCode, ...res }, null, 2);
            }

            return JSON.stringify({ refCode: part.split('?startapp=')[0] });
        } catch (error) {
            return 'Invalid link';
        }
    }

    return (
        <>
            <div className="flex flex-col">
                <Input
                    value={linkParams.utm_source}
                    onChange={(e) => onChange('utm_source', e)}
                    name="utm_source"
                    placeholder="utm_source"
                />
                <Input
                    value={linkParams.utm_content}
                    onChange={(e) => onChange('utm_content', e)}
                    name="utm_content"
                    placeholder="utm_content"
                />
                <Input
                    value={linkParams.utm_medium}
                    onChange={(e) => onChange('utm_medium', e)}
                    name="utm_medium"
                    placeholder="utm_medium"
                />
                <Input
                    value={refCode}
                    onChange={(e) => onChange('ref_code', e)}
                    name="ref_code"
                    placeholder="ref_code"
                />
            </div>
            <div style={{ backgroundColor: '#fff' }}>
                <strong>Generated Link:</strong>
                <p>{link || 'Your link will appear here.'}</p>
                <p>
                    start app length {link.split('?startapp=')?.[1]?.length} (please no longer than
                    512)
                </p>
                <button onClick={copyToClipboard} disabled={!link}>
                    {copied ? 'Copied!' : 'Copy to Clipboard'}
                </button>
            </div>

            <div style={{ backgroundColor: '#fff', marginTop: '30px' }}>
                <strong>Decoder</strong>
                <div className="flex flex-col">
                    <Input
                        value={linkToDecode}
                        onChange={(value) => setLinkToDecode(value)}
                        name="decodeLink"
                        placeholder="link to decode"
                    />
                    <p>decodedd link</p>
                    {linkToDecode && <p>{decodeLink(linkToDecode)}</p>}
                </div>
            </div>
        </>
    );
};
