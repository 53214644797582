import { CreateCoinScreenProps } from 'createToken/createCoin.page.tsx';
import { setCoinImage, setError, useCreateCoin } from 'createToken/createCoin.store.ts';
import classNames from 'classnames';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { useTranslation } from 'i18n';

export const ImageScreen = ({ goNext }: CreateCoinScreenProps) => {
    const { t } = useTranslation();
    const { imageSrc, image } = useCreateCoin();

    const canGoNext = !!image;

    const onSubmit = () => {
        if (!image) {
            return;
        }

        goNext();
    };

    return (
        <div
            className={classNames(
                `w-full flex flex-col h-full ${canGoNext ? 'pt-[10%] justify-between' : 'justify-center'}`
            )}
        >
            <label
                className={classNames(
                    'w-full max-w-[350px] aspect-square bg-black mx-auto flex border border-[#42A4FF66] border-dashed shadow-xl flex-col items-center justify-center gap-2.5',
                    {
                        [`rounded-xl border-none shadow-[0px_4px_24px_0px_#000000]`]: imageSrc,
                    }
                )}
                style={
                    imageSrc
                        ? {
                              backgroundImage: `url(${imageSrc})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                          }
                        : {}
                }
            >
                {!imageSrc && (
                    <>
                        <img src="/coin_image.gif" alt="" className="h-[70px]" />
                        <p className="text-[#42A4FF] font-medium text-md">
                            {t('createToken.selectImage')}
                        </p>
                    </>
                )}
                <input
                    type="file"
                    className="opacity-0 absolute"
                    accept="image/*"
                    onChange={(event) => {
                        if (!event.currentTarget.files) {
                            return;
                        }

                        const file = event.currentTarget.files[0];

                        // Проверка размера файла (3 МБ = 3 * 1024 * 1024 байт)
                        const maxSizeInBytes = 3 * 1024 * 1024;
                        if (file.size > maxSizeInBytes) {
                            setError(t('validation.fileMaxSize'));
                            return;
                        }

                        setCoinImage(event.currentTarget.files[0]);
                    }}
                />
            </label>

            <BigColorButton
                variant="yellow"
                className={classNames('py-2 transition-all', {
                    [`!h-0 !opacity-0`]: !canGoNext,
                    [`!h-[68px]`]: canGoNext,
                })}
                onClick={onSubmit}
                disabled={!canGoNext}
            >
                <div className="flex items-center gap-1">
                    <TextWithShadow>{t('createToken.image.save').toUpperCase()}</TextWithShadow>
                    <img src="/arrow_right.gif" alt="" className="h-7" />
                </div>
            </BigColorButton>
        </div>
    );
};
