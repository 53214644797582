import { Coin } from 'coin/coin.store.ts';
import { fetchHolders, THolder, useHolders } from 'coin/holders/holders.store.ts';
import { Skeleton } from 'common/components/skeleton.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { getRandomAvatar } from 'common/utils/getRandomAvatar.ts';
import { squeezeAddress } from 'common/utils/squeezeAddress.ts';
import { Address } from '@ton/core';
import { CopyButton } from 'common/components/copyButton.tsx';
import { normalizedToFixed } from 'common/utils/normalizedToFixed.ts';
import { EmptyList } from 'common/components/emptyList.tsx';
import { openExternalLink } from 'common/utils/openExternalLink.ts';

export const Holders = ({ tokenId }: { tokenId: Coin['id'] }) => {
    const { holders, isFetched } = useHolders();

    useAutoFetch(() => fetchHolders(tokenId));

    if (!isFetched) {
        return (
            <div className="flex flex-col gap-6">
                <Skeleton size="lg" />
                <Skeleton size="lg" />
                <Skeleton size="lg" />
            </div>
        );
    }

    if (isFetched && !holders.length) {
        return <EmptyList color="#CDCDCD" />;
    }

    return (
        <div className="flex flex-col">
            {holders.map((holder) => (
                <Holder key={holder.actorAddress} holder={holder} />
            ))}
        </div>
    );
};

export const Holder = ({ holder }: { holder: THolder }) => {
    const part = parseFloat(holder.sum) * 100;
    return (
        <div className="flex items-center gap-2 px-3 py-4 border-t border-[#0540B266]">
            <div className="flex gap-2.5 w-full items-center">
                <img
                    src={getRandomAvatar(holder.actorAddress ?? 'anonymous')}
                    alt=""
                    className="w-9 h-9 aspect-square"
                />
                <div>
                    <div className="flex items-center gap-2">
                        {holder.isAuthor ? (
                            <span className="bg-white/20 text-black pt-0.5 pl-1 pr-0.5 pb-[1px] text-2xs relative">
                                Creator
                                <span className="absolute top-[1px] left-[2px] text-2xs text-[#60F2FF]">
                                    Creator
                                </span>
                            </span>
                        ) : null}
                    </div>
                    <div className="flex items-center gap-1 mt-0.5">
                        <span className="text-xs font-normal text-white-gray">
                            {squeezeAddress(
                                Address.parse(holder.actorAddress).toString({
                                    bounceable: true,
                                })
                            )}
                        </span>
                        <CopyButton
                            text={Address.parse(holder.actorAddress).toString({
                                bounceable: true,
                            })}
                        >
                            <svg
                                width={10}
                                height={10}
                                viewBox="0 0 15 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4.25 0V4H0.25V14H10.25V10H14.25V0H4.25ZM13.25 1H5.25V4H10.25V9H13.25V1ZM9.25 13V5H1.25V13H9.25Z"
                                    fill="#CDCDCD"
                                />
                            </svg>
                        </CopyButton>
                        <span className="text-sm font-normal text-white-gray">/</span>
                        <button
                            onClick={() =>
                                openExternalLink(
                                    `https://tonviewer.com/${Address.parse(holder.actorAddress).toString({ bounceable: true })}`
                                )
                            }
                        >
                            <svg
                                width={12}
                                height={12}
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.1875 11.5L8.24968 15L14.3119 11.5V4.5L8.24968 1L2.1875 4.5V11.5ZM8.24968 2.1547L3.32093 5.00031L8.24963 7.84589L13.1784 5.00032L8.24968 2.1547ZM13.3119 10.9227V6.07797L8.75 8.71173V13.5564L13.3119 10.9227ZM7.75 13.5568L3.1875 10.9227V6.07796L7.75 8.71214V13.5568Z"
                                    fill="#CDCDCD"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <p className="ml-auto text-xs text-white">
                    {part >= 0.01 ? normalizedToFixed(part, 2) : '<0.01'}%
                </p>
            </div>
        </div>
    );
};
