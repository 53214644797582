import { Coin, CoinStatus } from 'coin/coin.store.ts';
import { ChartComponent } from 'common/components/chartComponents.tsx';
import { Button } from 'common/components/button.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { useCallback, useState } from 'preact/hooks';
import { Range, Time } from 'lightweight-charts';
import { Address } from '@ton/core';
import debounce from 'lodash-es/debounce';
import { useCleanupEffect } from 'common/hooks/useCleanupEffect.ts';
import { Skeleton } from 'common/components/skeleton.tsx';
import {
    clearKlines,
    fetchKlines,
    fetchKlinesRange,
    pullCoinKlines,
    useCoinChart,
} from 'coin/chart/coinChart.store.ts';

type ChartWithControlProps = {
    coin: Coin;
};

type Params = {
    fromDttm: Date;
    toDttm: Date;
    coinId: string;
    stepMinute: 1 | 5;
};

export const ChartWithControl = ({ coin }: ChartWithControlProps) => {
    const { serie, isFetched } = useCoinChart();

    useAutoFetch(() => fetchKlinesRange(coin.id));
    const [params, setParams] = useState<Params>({
        fromDttm: new Date(Date.now() - 3_600_00),
        toDttm: new Date(),
        coinId: coin.id,
        stepMinute: 1,
    });

    const onParamChange = (changedParams: Partial<Params>) => {
        setParams((prev) => {
            const newParams = {
                ...prev,
                ...changedParams,
                coinId: coin.id,
            };
            if (isFetched) {
                fetchKlines({
                    fromDttm: newParams.fromDttm.getTime(),
                    toDttm: newParams.toDttm.getTime(),
                    coinId: coin.id,
                    stepMinute: newParams.stepMinute,
                });
            }
            return newParams;
        });
    };

    const onStepMinuteChange = useCallback(
        (stepMinute: 1 | 5) => {
            clearKlines();
            onParamChange({ stepMinute });
        },
        [onParamChange]
    );

    const onVisibleRangeChange = useCallback(
        ({ from, to }: Range<Time>) => {
            const fromDttm = new Date((from as number) * 1000);
            const toDttm = new Date((to as number) * 1000);

            onParamChange({
                fromDttm,
                toDttm,
            });
        },
        [onParamChange]
    );

    const pullKlines = useCallback(() => {
        pullCoinKlines({ coinId: coin.id, stepMinute: params.stepMinute });
    }, [params, coin, isFetched]);

    useAutoFetch(pullKlines, 5000, 5000);

    useCleanupEffect(clearKlines);

    const debounceOnVisibleRangeChange = useCallback(debounce(onVisibleRangeChange, 500), [
        onVisibleRangeChange,
    ]);

    return (
        <div className="flex flex-col gap-2 w-full">
            {coin.status === CoinStatus.LiquiditySent ? (
                <iframe
                    src={`https://dexscreener.com/ton/${Address.parse(coin.address).toString({ urlSafe: true, bounceable: true })}?embed=1&trades=0&info=0`}
                    className="w-full h-[300px]"
                />
            ) : (
                <>
                    {serie.length ? (
                        <ChartComponent
                            data={serie}
                            onVisibleRangeChange={debounceOnVisibleRangeChange}
                            type="area"
                        />
                    ) : (
                        <Skeleton height={300} />
                    )}
                    <div className="grid grid-cols-2 gap-1.5 w-full">
                        <Button
                            variant="blueGradient"
                            className="border-black h-8"
                            onClick={() => onStepMinuteChange(1)}
                        >
                            1 min
                        </Button>
                        <Button
                            variant="blueGradient"
                            className="border-black h-8"
                            onClick={() => onStepMinuteChange(5)}
                        >
                            5 min
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
