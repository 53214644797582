import ru from './ru.json';
import en from './en.json';
import WebApp from '@twa-dev/sdk';
import { getSearchParams } from 'common/utils/searchUtils.ts';
import dayjs from 'dayjs';

export enum Locale {
    ru = 'ru',
    en = 'en',
}

export type Dictionary = typeof ru | typeof en;

const dictionaries: Record<Locale, Dictionary> = {
    en,
    ru,
};

export const getDictionary = (locale: Locale) => dictionaries[locale];

const getLangSearchParams = (): Locale | null => {
    const params = getSearchParams();
    const lang = params.get('lang');
    if (lang === Locale.en || lang === Locale.ru) {
        return lang;
    }
    return null;
};

class Language {
    language = Locale.en;

    constructor() {
        const locale =
            WebApp.initDataUnsafe.user?.language_code ||
            getLangSearchParams() ||
            navigator.language;

        if (locale && locale in Locale) {
            this.setLanguage(locale as Locale);
        } else {
            this.setLanguage(Locale.en);
        }
    }

    setLanguage(language: Locale) {
        if (!language || !(language in Locale)) {
            language = Locale.en;
        }
        this.language = language;
        setTimeout(() => dayjs.locale(language), 100);
    }
}

const language = new Language();

export const t = (key: keyof Dictionary, dictionary = getDictionary(language.language)) =>
    dictionary[key];

export const useTranslation = () => {
    const dictionary = getDictionary(language.language);
    const translate = (key: keyof Dictionary) => t(key, dictionary);

    return { t: translate, lang: language.language };
};

export type TranslateFn = (key: keyof Dictionary) => string;

export default language;

export function isDictionaryKey(key: string): key is keyof Dictionary {
    return key in en && key in ru;
}

export * from './utils';
