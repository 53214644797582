import { ReactNode } from 'preact/compat';
import { useTon } from 'main/main.store.ts';

type Props = {
    value: number;
    className?: string;
    icon?: string | ReactNode;
};

export const AuthorWalletBalance = ({ value, className, icon = '/wallet_.svg' }: Props) => {
    const { price } = useTon();
    const amount = value
        ? Intl.NumberFormat('en', {
              style: 'currency',
              currency: 'USD',
              notation: 'compact',
              maximumFractionDigits: 1,
          }).format(Number(value) * price)
        : 'n/a';

    return (
        <div className={`flex items-center ${className}`}>
            {typeof icon === 'string' ? <img src={icon} alt="" className="w-4 h-4" /> : icon}
            <span className="text-xs leading-tight">{amount}</span>
        </div>
    );
};
